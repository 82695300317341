import * as actionTypes from './actionTypes';

export interface IState {
  isSearchFetching: boolean,
  searchText: string,
  searchResult: {},
}

const initialState: IState = {
  isSearchFetching: false,
  searchText: '',
  searchResult: {},
};

export default function header(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SEARCH_TEXT_CHANGE:
      return {
        ...state,
        searchText: action.payload,
      };
    case actionTypes.GET_HEADER_SEARCH_RESULTS:
      return {
        ...state,
        isSearchFetching: true,
      };
    case actionTypes.GET_HEADER_SEARCH_RESULTS_SUCCESS:
      return {
        ...state,
        isSearchFetching: false,
        searchResult: action.payload,
      };
    case actionTypes.GET_HEADER_SEARCH_RESULTS_FAILURE:
      return {
        ...state,
        isSearchFetching: false,
      };
    case actionTypes.RESET_SEARCH_RESULT:
      return {
        ...state,
        searchResult: {},
        searchText: '',
      };
    default:
      return state;
  }
}
