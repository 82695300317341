import { connect } from 'react-redux';
import ResultsColumn from './ResultsColumn';

const mapStateToProps = (state) => ({
  searchResult: state.headerData.searchResult,
});

export default connect(
  mapStateToProps,
)(ResultsColumn);
