import * as actionTypes from './actionTypes';

const initialState = {
  isFetching: false,
  isNotFound: false,
  route: {},
};

export default function backendRedirect(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_BACKEND_REDIRECT:
      return {
        ...state,
        isFetching: true,
      };
    case actionTypes.GET_BACKEND_REDIRECT_SUCCESS:
      return {
        ...state,
        route: action.payload,
        isFetching: false,
      };
    case actionTypes.GET_BACKEND_REDIRECT_FAILURE:
      return {
        ...state,
        route: {},
        isFetching: false,
      };
    case actionTypes.SET_REDIRECT_NOT_FOUND:
      return {
        ...state,
        isNotFound: action.payload,
      };
    case actionTypes.RESET_REDIRECT:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
