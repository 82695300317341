import flatten from 'lodash/flatten';
import { SEARCH_RESULT_FIRST_COLUMN_CONFIG } from '@/finsight/ui/common/components/header/Header/constants';
import Tool from '@/finsight/domain/Tool';
import getSearchResultItemUrl from './getSearchResultsItemUrl';

/**
 * These two mappers are creating matriсes for arrow navigation.
 * They return arrays, each item of which is a navigation column
 * with an array of navigation links of the same structure,
 * so we can navigate through menu by using arrows and enter keys.
 *
 * @param {Array} tools
 * @param {Array} menuData
 * @param {Object} filteredActiveMapping
 * @param {Object} filtersValues
 * @return {Object} matrix
 * */

export const getMappedMenuData = (tools, menuData, filteredActiveMapping, filtersValues) => {
  // getting active filters and creating search string
  const { activeProduct, activeRegion } = filtersValues;
  const searchString = `?products=${ activeProduct }&regions=${ activeRegion }`;

  // mapping through each tool to create the same structure for each link
  const toolsData = tools?.map(
    (tool) => ({
      isExternal: tool.external,
      id: tool.id,
      link: Tool.getToolLink({
        isExternal: tool.external,
        id: tool.id,
        route: tool.route,
        skipProductRegionAppend: tool.skipProductRegionAppend,
        activeProductAbbreviation: activeProduct,
        activeRegionAbbreviation: activeRegion,
      }),
    }),
  );

  // get columns from menuData
  const columns = flatten(menuData);

  // mapping through each column to create the same structure for each menu link
  const handledColumns = columns.map((column) => {
    let currentColumnLinks = [];

    // mapping through each block of menu to get access to menu links
    column.forEach(
      (columnBlock) => {
        let columnBlockLinks = [];

        // if current link is active - adding to links list for current column
        if (filteredActiveMapping[columnBlock.id]) {
          columnBlockLinks.push({
            id: columnBlock.id,
            link: `/${ columnBlock.link }${ searchString }`,
          });

          // if current block has children links - do the same for each child inside
          if (columnBlock.children.length) {
            columnBlock.children.forEach(
              (child) => {
                if (filteredActiveMapping[columnBlock.id][child.id]) {
                  columnBlockLinks.push({
                    id: child.id,
                    link: `/${ child.link }${ searchString }`,
                  });
                }
              },
            );
          }
        }

        // merge links from each block into one column of active links.
        currentColumnLinks = [...currentColumnLinks, ...columnBlockLinks];
      },
    );
    return currentColumnLinks;
  });

  // merge tools column with other columns
  const mergedColumns = [
    toolsData,
    ...handledColumns,
  ];

  // skip all empty columns and mark if tools column exists (tools column navigation differ from other)
  return {
    menuMatrix: mergedColumns.filter((column) => column.length),
    isWithToolbar: !!toolsData.length,
  };
};

/**
 * @param {Array} searchResult
 * @param {Array} products
 * @param {Array} regions
 * @returns {Array} matrix
 */
export const getMappedSearchData = (searchResult, products, regions) => {
  let firstColumnData = [];

  // creating first search column from config and search results
  // to create the same structure for each search link
  if (searchResult.hasFirstColumnData) {
    SEARCH_RESULT_FIRST_COLUMN_CONFIG.forEach(
      (firstColumnBlock) => {
        firstColumnData = [
          ...firstColumnData,
          ...(searchResult[firstColumnBlock.key].collection.map(
            (resultItem) => {
              const resultItemUrl = resultItem.url || '';
              return ({
                id: resultItem.name + resultItemUrl,
                link: getSearchResultItemUrl(resultItem),
              });
            },
          )),
        ];
      },
    );
  }

  // for each product is a column
  const searchResultsColumns = products.map(
    (product) => {
      // create a matrix column
      let searchResultsColumnLinks = [];

      regions.forEach(
        (region) => {
          // create key with combination of product and region to get access
          // to the next block of links for this column
          const key = `${ product.abbreviation.toLowerCase() }_${ region.abbreviation.toLowerCase() }`;

          // fill column with next block of links
          searchResult.deals[key].collection.forEach(
            (searchResultItem) => {
              // need to combine link's name, link's url and region
              // to create an unique id for this link
              const searchResultItemUrl = searchResultItem.url || '';
              searchResultsColumnLinks.push({
                id: searchResultItem.name + searchResultItemUrl + product.abbreviation + region.abbreviation,
                link: `/${ searchResultItem.url }`,
              });
            },
          );
        },
      );
      return searchResultsColumnLinks;
    },
  );
  // merge first column with other columns
  const mergedColumns = [
    firstColumnData,
    ...searchResultsColumns,
  ];
  // return only columns with links
  return mergedColumns.filter((column) => column.length);
};
