import { connect } from 'react-redux';
import { onSearchResultItemClick } from '@/finsight/application/actions/header/actions';
import SearchResults from './SearchResults';

const mapStateToProps = (state) => ({
  searchText: state.headerData.searchText,
  searchResult: state.headerData.searchResult,
  isSearchFetching: state.headerData.isSearchFetching,
});

const mapDispatchToProps = {
  onSearchResultItemClick,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchResults);
