import React from 'react';
import styles from './SubscriptionsBackground.scss';
import envelope from './assets/envelope.svg';

const SubscriptionsBackground = () => (
  <img
    src={ envelope }
    className={ styles.subscriptionsBackground }
    width={ 619 }
    height={ 520 }
  />
);

export default SubscriptionsBackground;
