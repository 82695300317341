import { connect } from 'react-redux';
import { resetRedirect } from '@/finsight/application/actions/redirect/actions';
import * as selectors from '@/finsight/application/actions/redirect/selectors';
import PageNotFoundChecker from './PageNotFoundChecker';

const mapStateToProps = (state) => (
  {
    isNotFound: selectors.getPageIsNotFound(state),
    pathname: state.router.location.pathname,
  }
);

const mapDispatchToProps = {
  resetRedirect,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PageNotFoundChecker);
