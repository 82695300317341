import {
  INIT_UNSUBSCRIBE_FROM_ISSUE_PIPELINE,
  INIT_UNSUBSCRIBE_FROM_ISSUE_PIPELINE_SUCCESS,
  INIT_UNSUBSCRIBE_FROM_ISSUE_PIPELINE_ERROR,
} from '@/finsight/application/actions/issuePipeline/actionTypes';

const initialState = {
  isFetching: false,
};

export default function unsubscribe(state = initialState, action) {
  switch (action.type) {
    case INIT_UNSUBSCRIBE_FROM_ISSUE_PIPELINE:
      return {
        ...state,
        isFetching: true,
      };
    case INIT_UNSUBSCRIBE_FROM_ISSUE_PIPELINE_ERROR:
    case INIT_UNSUBSCRIBE_FROM_ISSUE_PIPELINE_SUCCESS:
      return {
        ...state,
        isFetching: false,
      };
    default:
      return state;
  }
}
