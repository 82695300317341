export const SEND_CORRECTION_MODAL = 'SEND_CORRECTION_MODAL';
export const SEND_CORRECTION_MODAL_FAILURE = 'SEND_CORRECTION_MODAL_FAILURE';
export const SEND_CORRECTION_MODAL_SUCCESS = 'SEND_CORRECTION_MODAL_SUCCESS';

export const SEND_MISSING_SOMETHING_MODAL = 'SEND_MISSING_SOMETHING_MODAL';
export const SEND_MISSING_SOMETHING_MODAL_FAILURE = 'SEND_MISSING_SOMETHING_MODAL_FAILURE';
export const SEND_MISSING_SOMETHING_MODAL_SUCCESS = 'SEND_MISSING_SOMETHING_MODAL_SUCCESS';

export const SET_IS_MISSING_SOMETHING_MODAL_VISIBLE = 'SET_IS_MISSING_SOMETHING_MODAL_VISIBLE';
export const SET_IS_CORRECTION_MODAL_VISIBLE = 'SET_IS_CORRECTION_MODAL_VISIBLE';

export const RESET_FINSIGHT_MODAL_FORM = 'RESET_FINSIGHT_MODAL_FORM';
