export const EQUITIES = {
  abbreviation: 'EQT',
  displayName: 'U.S. Equities',
  id: 1,
  marketDataName: 'EQT',
  mobileAbbreviation: 'U.S. Equities (EQT)',
  name: 'U.S. Equities',
};

export const ACTIVE_EQUITIES_MAPPING = {
  tools: [
    {
      outOfDomain: true,
      external: true,
      id: '1f8fc265-8ffe-4ba3-ad3d-6b163ef56948',
      name: 'CapEdge Home',
      path: 'https://capedge.com/',
    },
    {
      outOfDomain: true,
      external: true,
      id: '4f8fc265-8ffe-4ba3-ad3d-6b163ef56948',
      name: 'Earnings Calendar',
      path: 'https://capedge.com/earnings-calendar',
    },
    {
      outOfDomain: true,
      external: true,
      id: '7f8fc265-8ffe-4ba3-ad3d-6b163ef56948',
      name: 'Earnings Transcripts',
      path: 'https://capedge.com/transcript/by/companyId/all',
    },
    {
      outOfDomain: true,
      external: true,
      id: '1d8fc265-8ffe-4ba3-ad3d-6b163ef56948',
      name: 'New Issue Registration',
      path: 'https://capedge.com/search?q=S-1+S-1%2FA+F-1+F-1%2FA+S-11+S-11%2FA',
    },
    {
      outOfDomain: true,
      external: true,
      id: '2f8fc265-8ffe-4ba3-ad3d-6b163ef56948',
      name: 'IPO Calendar',
      path: 'https://capedge.com/ipo-calendar',
    },
    {
      outOfDomain: true,
      external: true,
      id: '5f8fc265-8ffe-4ba3-ad3d-6b163ef56948',
      name: 'Insider Holdings',
      path: 'https://capedge.com/insider',
    },
    {
      outOfDomain: true,
      external: true,
      id: '8f8fc265-8ffe-4ba3-ad3d-6b163ef56948',
      name: 'Institutional Holdings',
      path: 'https://capedge.com/institutional',
    },
    {
      outOfDomain: true,
      external: true,
      id: '2fd26681-f4f5-4eb6-aa95-4a780fe1b353',
      name: 'Screener (of EDGAR)',
      path: 'https://capedge.com/screener/stock',
    },
    {
      outOfDomain: true,
      external: true,
      id: '3f8fc265-8ffe-4ba3-ad3d-6b163ef56948',
      name: 'SPACs',
      path: 'https://capedge.com/spacs',
    },
    {
      outOfDomain: true,
      external: true,
      id: '6f8fc265-8ffe-4ba3-ad3d-6b163ef56949',
      name: 'Splits',
      path: 'https://capedge.com/stock-split-calendar',
    },
    {
      outOfDomain: true,
      external: true,
      id: '9f8fc265-8ffe-4ba3-ad3d-6b163ef56948',
      name: 'Crypto',
      path: 'https://capedge.com/crypto',
    },
    {
      outOfDomain: true,
      external: true,
      id: '1d8fc265-8ffe-4ba3-ad3d-6b163ef56949',
      name: 'Other: Reddit',
      path: 'https://capedge.com/social',
    },
  ],
  sectorsMenu: {
    0: [
      {
        link: 'https://capedge.com/sector/accommodation-and-food-services',
        name: 'Accommodation and Food Services',
      },
      {
        link: 'https://capedge.com/sector/administrative-and-support-and-waste-management-and-remediation-services',
        name: 'Administrative and Support and Waste Management and Remediation Services',
      },
      {
        link: 'https://capedge.com/sector/agriculture-forestry-fishing-and-hunting',
        name: 'Agriculture, Forestry, Fishing and Hunting',
      },
      {
        link: 'https://capedge.com/sector/arts-entertainment-and-recreation',
        name: 'Arts, Entertainment and Recreation',
      },
      {
        link: 'https://capedge.com/sector/commercial-services',
        name: 'Commerical Services',
      },
      {
        link: 'https://capedge.com/sector/communications',
        name: 'Communications',
      },
      {
        link: 'https://capedge.com/sector/construction',
        name: 'Construction',
      },
      {
        link: 'https://capedge.com/sector/consumer-durables',
        name: 'Consumer Durables',
      },
      {
        link: 'https://capedge.com/sector/consumer-non-durables',
        name: 'Consumer Non-Durables',
      },
      {
        link: 'https://capedge.com/sector/consumer-services',
        name: 'Consumer Services',
      },
      {
        link: 'https://capedge.com/sector/distribution-services',
        name: 'Distribution Services',
      },
    ],
    1: [
      {
        link: 'https://capedge.com/sector/educational-services',
        name: 'Educational Services',
      },
      {
        link: 'https://capedge.com/sector/electronic-technology',
        name: 'Electronic Technology',
      },
      {
        link: 'https://capedge.com/sector/energy-minerals',
        name: 'Energy Minerals',
      },
      {
        link: 'https://capedge.com/sector/finance',
        name: 'Finance',
      },
      {
        active: false,
        id: 'f20e6790-84c2-46a1-92cf-246420841075',
        link: 'https://capedge.com/sector/finance-and-insurance',
        name: 'Finance and Insurance',
      },
      {
        link: 'https://capedge.com/sector/government',
        name: 'Government',
      },
      {
        link: 'https://capedge.com/sector/health-care-and-social-assistance',
        name: 'Health Care and Social Assistance',
      },
      {
        link: 'https://capedge.com/sector/health-services',
        name: 'Health Services',
      },
      {
        link: 'https://capedge.com/sector/health-technology',
        name: 'Health Technology',
      },
      {
        link: 'https://capedge.com/sector/industrial-services',
        name: 'Industrial Services',
      },
      {
        link: 'https://capedge.com/sector/information',
        name: 'Information',
      },
    ],
    2: [
      {
        link: 'https://capedge.com/sector/management-of-companies-and-enterprises',
        name: 'Management of Companies and Enterprises',
      },
      {
        link: 'https://capedge.com/sector/manufacturing',
        name: 'Manufacturing',
      },
      {
        link: 'https://capedge.com/sector/mining-quarrying-and-oil-and-gas-extraction',
        name: 'Mining, Quarrying, and Oil and Gas Extraction',
      },
      {
        link: 'https://capedge.com/sector/miscellaneous',
        name: 'Miscellaneous',
      },
      {
        link: 'https://capedge.com/sector/non-energy-minerals',
        name: 'Non-Energy Minerals',
      },
      {
        link: 'https://capedge.com/sector/other-services-except-public-administration',
        name: 'Other Services (except Public Administration)',
      },
      {
        link: 'https://capedge.com/sector/process-industries',
        name: 'Process Industries',
      },
      {
        link: 'https://capedge.com/sector/producer-manufacturing',
        name: 'Producer Manufacturing',
      },
      {
        link: 'https://capedge.com/sector/professional-scientific-and-technical-services',
        name: 'Professional, Scientific and Technical Services',
      },
      {
        link: 'https://capedge.com/sector/public-administration',
        name: 'Public Administration',
      },
    ],
    3: [
      {
        link: 'https://capedge.com/sector/real-estate-and-rental-and-leasing',
        name: 'Real Estate and Rental and Leasing',
      },
      {
        link: 'https://capedge.com/sector/retail-trade',
        name: 'Retail Trade',
      },
      {
        link: 'https://capedge.com/sector/technology-services',
        name: 'Technology Services',
      },
      {
        link: 'https://capedge.com/sector/transportation',
        name: 'Transportation',
      },
      {
        link: 'https://capedge.com/sector/transportation-and-warehousing',
        name: 'Transportation and Warehousing',
      },
      {
        link: 'https://capedge.com/sector/utilities',
        name: 'Utilities',
      },
      {
        link: 'https://capedge.com/sector/wholesale-trade',
        name: 'Wholesale Trade',
      },
    ],
  },
};
