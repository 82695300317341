import React, { useState } from 'react';
import cn from 'classnames';
import { Icon, IconType } from '@dealroadshow/uikit';
import styles from '@/finsight/ui/common/components/header/Header/MarketDataMenu/Content/content.scss';

const HelperTooltip = () => {
  const [tooltip, triggerTooltip] = useState<boolean>(true);

  const hide = () => triggerTooltip(!tooltip);

  return (
    <div className={ cn(styles.capEdgeTooltip, { [styles.closed]: !tooltip }) }>
      FINSIGHT is excited to introduce CapEdge <a href="https://capedge.com/" target="_blank">(www.CapEdge.com)</a>, a 100%
      free new service that enables you
      to track equity markets and instantly search across millions of SEC filings and earnings transcripts.
      CapEdge offers critical insights, thoughtfully organized, with real-time alerts.
      <Icon
        type={ IconType.close }
        onClick={ hide }
      />
    </div>
  );
};

export default HelperTooltip;
