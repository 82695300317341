import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MissingSomethingModalButton from './MissingSomethingModalButton';
import { setIsMissingSomethingModalVisible } from '@/finsight/application/actions/modalForms/actions';

const mapDispatchToProps = (dispatch) => bindActionCreators({
  setIsVisible: () => setIsMissingSomethingModalVisible(true),
}, dispatch);

export default connect(
  null,
  mapDispatchToProps,
)(MissingSomethingModalButton);
