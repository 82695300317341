import React, { FC } from 'react';
import styles from './styles.scss';

interface IProps {
  children: React.ReactNode,
}

const PageContentLayout: FC<IProps> = ({ children }) => (
  <div className={ styles.pageContainer }>
    <div className={ styles.pageContentHolder }>
      { children }
    </div>
  </div>
);

export default PageContentLayout;
