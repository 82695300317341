import cn from 'classnames';
import React, { useMemo, useCallback } from 'react';
import { SEARCH_RESULT_FIRST_COLUMN_CONFIG } from '@/finsight/ui/common/components/header/Header/constants';
import ResultsColumn, { ResultsItem } from './ResultsColumn';
import styles from './searchResults.scss';
import columnStyles from './ResultsColumn/resultsColumn.scss';
import MissingSomethingModalButton from '@/finsight/ui/common/components/MissingSomethingModalButton';
import useArrowNavigation from '@/finsight/ui/common/components/header/Header/helpers/useArrowNavigation';
import { useHeaderMenuContext } from '@/finsight/ui/common/components/header/Header/HeaderMenuContext';
import { getMappedSearchData } from '@/finsight/ui/common/components/header/Header/helpers/mappers';
import { useDictionariesContext } from '@/finsight/application/Dictionaries/DictionariesContext';

interface IProps {
  searchText: string,
  searchResult: any,
  isSearchFetching: boolean,
  onSearchResultItemClick: () => void,
}

const renderNoSearchResult = (searchText) => (
  <div
    className={ styles.noSearchResults }
    data-test="noSearchResultsContent"
  >
    <div>
      No results found for "<span className={ styles.searchText }>{ searchText }</span>".
    </div>
    <div>
      <MissingSomethingModalButton />
    </div>
  </div>
);

const SearchResults = (props: IProps) => {
  const { setIsSearchResultsOpen } = useHeaderMenuContext();
  const {
    productsList: products,
    regionsList: regions,
  } = useDictionariesContext();
  const reversedRegions = useMemo(() => [...regions].reverse(), [regions]);

  const searchResultsMatrix = useMemo(() => {
    if (!props.isSearchFetching && props.searchResult.hasData && reversedRegions.length) {
      return getMappedSearchData(props.searchResult, products, reversedRegions);
    }
    return null;
  }, [props.isSearchFetching, props.searchResult, products, reversedRegions]);

  const activeItemName = useArrowNavigation(
    searchResultsMatrix,
    (props.searchResult.hasData || !props.isSearchFetching),
  );

  // @ts-ignore
  const onSearchResultItemClick = useCallback(() => {
    // @ts-ignore
    setIsSearchResultsOpen(false);
    if (typeof props.onSearchResultItemClick === 'function') {
      props.onSearchResultItemClick();
    }
  });

  return (
    <div className={ cn(styles.resultsContainer, { [styles.noResults]: !props.searchResult.hasData }) }>
      {
        props.searchResult.hasData
          ? (
            <div
              className={ styles.row }
              data-test="searchResultsContent"
            >
              { props.searchResult.hasFirstColumnData && (
                <div className={ columnStyles.column }>
                  { SEARCH_RESULT_FIRST_COLUMN_CONFIG.map((item) => (
                    <ResultsItem
                      // @ts-ignore
                      activeItemName={ activeItemName }
                      key={ item.key }
                      title={ item.title }
                      data={ props.searchResult[item.key].collection }
                      onClick={ onSearchResultItemClick }
                      hideOnNoResults
                    />
                  )) }
                </div>
              ) }
              { products.map((product) => (
                <ResultsColumn
                  activeItemName={ activeItemName }
                  key={ product.id }
                  product={ product }
                  onSearchResultItemClick={ onSearchResultItemClick }
                />
              )) }
            </div>
          )
          : !props.isSearchFetching && renderNoSearchResult(props.searchText)
      }
    </div>
  );
};

export default SearchResults;
