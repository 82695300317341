import React from 'react';
import { Icon, IconType } from '@dealroadshow/uikit';
import styles from './searchInput.scss';

const SearchIcon = () => (
  <div className={ styles.searchIconContainer }>
    <Icon
      type={ IconType.search }
      className={ styles.searchIcon }
    />
  </div>
);

export default SearchIcon;
