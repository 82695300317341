import { Dependencies } from 'constitute';
import Request from '@/Framework/api/Rpc/Request';
import { RpcSuccess } from '@dealroadshow/json-rpc-dispatcher';
import FinsightWebRpcDispatcher from '@/finsight/application/DI/Rpc/finsight-web/HttpDispatcher';

@Dependencies(FinsightWebRpcDispatcher)
class BackendRedirectRepository {
  constructor(protected rpc: typeof FinsightWebRpcDispatcher) {}

  /**
   * Gets new route for old url(deal|sponsor|issuer/:viewId)
   */
  getNewRoute = async (url: string): Promise<{ url: string, code: string }> => {
    const request = new Request('finsight_web.seo.get_redirect', { url });
    const response = await this.rpc.call<RpcSuccess>(request);

    return response.getResult().payload;
  };
}

export default BackendRedirectRepository;
