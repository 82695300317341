import React from 'react';
import HeaderLinkItem from '@/finsight/ui/common/components/header/Header/HeaderLinkItem';
import getSearchResultItemUrl from '@/finsight/ui/common/components/header/Header/helpers/getSearchResultsItemUrl';
import headerStyles from '@/finsight/ui/common/components/header/Header/header.scss';
import styles from './resultsColumn.scss';

interface IProps {
  title: string,
  isBeta?: boolean,
  data: any[],
  hideOnNoResults?: boolean,
  onClick: () => void,
  activeItemName?: string,
  regionAbbreviation?: string,
  productAbbreviation?: string,
}

const ResultsItem = React.memo(({
  activeItemName = '',
  regionAbbreviation = '',
  productAbbreviation = '',
  ...props
}: IProps) => {
  const hasResults = !!props.data.length;

  if (props.hideOnNoResults && !hasResults) {
    return null;
  }

  return (
    <div className={ styles.resultsBlock }>
      <div className={ styles.title }>
        { props.title && props.title }
        { props.isBeta && (
          <span className={ headerStyles.beta }>(Beta)</span>
        ) }
      </div>
      { hasResults
        ? props.data.map((item) => {
          const itemUrl = item.url || '';
          const currentItemName = item.name + itemUrl + productAbbreviation + regionAbbreviation;
          return (
            <HeaderLinkItem
              linkTo={ getSearchResultItemUrl(item) }
              isActive={ activeItemName === currentItemName }
              activeClassName={ styles.activeResultItem }
              className={ styles.resultsItem }
              onClick={ props.onClick }
              key={ currentItemName }
            >
              { item.name }
            </HeaderLinkItem>
          );
        })
        : (
          <div className={ styles.noResultsBlock }>
            No results for this region
          </div>
        ) }
    </div>
  );
});

export default ResultsItem;
