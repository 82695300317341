import React from 'react';
import DictionariesContextProvider from '@/finsight/application/Dictionaries/DictionariesContext';
import RatingAgenciesMappingsContextProvider
  from '@/finsight/application/RatingAgenciesMappings/RatingAgenciesMappingsContext';
import { IDictionariesState } from '@/finsight/application/Dictionaries/helpers';
import UserPlanContextProvider from '@/finsight/application/User/UserPlan/UserPlanContext';
import { IUserPlanState } from '@/finsight/application/User/UserPlan/helpers';

interface IProps {
  children: React.ReactNode,
  dictionaries?: IDictionariesState,
  userPlanState?: IUserPlanState,
}

const FinsightGlobalProps = ({ children, dictionaries, userPlanState }: IProps) => (
  <DictionariesContextProvider dictionaries={ dictionaries }>
    <RatingAgenciesMappingsContextProvider>
      <UserPlanContextProvider userPlanState={ userPlanState }>
        { children }
      </UserPlanContextProvider>
    </RatingAgenciesMappingsContextProvider>
  </DictionariesContextProvider>
);

export default FinsightGlobalProps;
