import { connect } from 'react-redux';
import SearchResultsMenu from './SearchResultsMenu';
import * as modalFormSelectors from '@/finsight/application/actions/modalForms/selectors';

const mapStateToProps = (state) => ({
  isSearchFetching: state.headerData.isSearchFetching,
  searchText: state.headerData.searchText,
  isMissingSomethingModalVisible: modalFormSelectors.isMissingSomethingModalVisible(state),
});

export default connect(
  mapStateToProps,
)(SearchResultsMenu);
