import React, { createContext, useContext, useEffect, useState } from 'react';
import { useDictionariesContext } from '@/finsight/application/Dictionaries/DictionariesContext';

const useRatingAgenciesMappings = (ratingAgenciesMap) => {
  const [ratingAgenciesMappings, setRatingAgenciesMappings] = useState({
    agenciesMapping: ratingAgenciesMap,
    agenciesMappingInit: !!Object.keys(ratingAgenciesMap).length,
  });

  useEffect(() => {
    setRatingAgenciesMappings({
      agenciesMapping: ratingAgenciesMap,
      agenciesMappingInit: !!Object.keys(ratingAgenciesMap).length,
    });
  }, [ratingAgenciesMap]);

  return ratingAgenciesMappings;
};

export const RatingAgenciesMappingsContext = createContext(null);

export const useRatingAgenciesMappingsContext = () => {
  const context = useContext(RatingAgenciesMappingsContext);
  if (!context) {
    throw new Error('useRatingAgenciesMappings must be used within the RatingAgenciesMappingsContext');
  }
  return context;
};

const RatingAgenciesMappingsContextProvider = ({ children }) => {
  const { ratingAgenciesMap } = useDictionariesContext();
  return (
    <RatingAgenciesMappingsContext.Provider value={ useRatingAgenciesMappings(ratingAgenciesMap) }>
      { children }
    </RatingAgenciesMappingsContext.Provider>
  );
};

export default RatingAgenciesMappingsContextProvider;
