export const GET_DEALS = 'GET_DEALS';
export const GET_DEALS_SUCCESS = 'GET_DEALS_SUCCESS';
export const GET_DEALS_FAILURE = 'GET_DEALS_FAILURE';

export const GET_DEALS_TILE = 'GET_DEALS_TILE';
export const GET_DEALS_TILE_SUCCESS = 'GET_DEALS_TILE_SUCCESS';
export const GET_DEALS_TILE_FAILURE = 'GET_DEALS_TILE_FAILURE';

export const GET_BUFFER_DEALS = 'GET_BUFFER_DEALS';
export const GET_BUFFER_DEALS_SUCCESS = 'GET_BUFFER_DEALS_SUCCESS';

export const GET_MORE_DEALS = 'GET_MORE_DEALS';
export const GET_MORE_DEALS_SUCCESS = 'GET_MORE_DEALS_SUCCESS';
export const GET_MORE_DEALS_FAILURE = 'GET_MORE_DEALS_FAILURE';

export const MERGE_DEALS_WITH_BUFFER = 'MERGE_DEALS_WITH_BUFFER';
export const SET_PAGE_TITLE = 'SET_PAGE_TITLE';
export const SET_DEALS_PAGE = 'SET_DEALS_PAGE';
export const RESET_DEALS = 'RESET_DEALS';

export const CREATE_DEALS_SUBSCRIPTION = 'CREATE_DEALS_SUBSCRIPTION';
export const CREATE_DEALS_SUBSCRIPTION_FAILURE = 'CREATE_DEALS_SUBSCRIPTION_FAILURE';
export const CREATE_DEALS_SUBSCRIPTION_SUCCESS = 'CREATE_DEALS_SUBSCRIPTION_SUCCESS';
export const RESET_DEALS_SUBSCRIBE_MODAL_FORM = 'RESET_DEALS_SUBSCRIBE_MODAL_FORM';
