import cn from 'classnames';
import dynamic from 'next/dynamic';
import React, { memo } from 'react';
import { Link } from '@/Framework/Router/Next/Link';
import { Icon, IconType } from '@dealroadshow/uikit';
import { isScreenS } from '@dealroadshow/uikit/dist/lib/styles/screen/screen';
import { MarketDataMenuButton } from '@/finsight/ui/common/components/header/Header/MarketDataMenu';
import { SearchInput } from '@/finsight/ui/common/components/header/Header/SearchResultsMenu';
import URL from '@/finsight/Router/urlConstants';
import UserProfile from './UserProfile';
import { useHeaderMenuContext } from '@/finsight/ui/common/components/header/Header/HeaderMenuContext';
import HeaderContainer from './HeaderContainer';

/* eslint-disable import/first */
const MarketDataMenu = dynamic(import('@/finsight/ui/common/components/header/Header/MarketDataMenu'), { ssr: false });
const SearchResultMenu = dynamic(import('@/finsight/ui/common/components/header/Header/SearchResultsMenu'), { ssr: false });

import finsightLogoText from '@/Framework/assets/logo/finsightLogoText.inline.svg';
import logoWings from '@/Framework/assets/logo/logoWings.inline.svg';
import styles from './header.scss';

interface IProps {
  hideSearch: boolean,
  transparent: boolean,
}

const Header = ({ hideSearch, transparent = false }: IProps) => {
  const {
    isMobileMenuOpen,
    isMarketDataMenuOpen,
    isSearchResultsOpen,
    setIsMobileMenuOpen,
    setIsMarketDataMenuOpen,
  }: any = useHeaderMenuContext();
  const toggleMobileMenu = () => {
    if (isMobileMenuOpen && isMarketDataMenuOpen) {
      setIsMarketDataMenuOpen(false);
    }

    // repaint mobile user menu for correct position
    if (!isMobileMenuOpen) {
      window.dispatchEvent(new Event('resize'));
    }

    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMenu = () => {
    if (isMobileMenuOpen) {
      setIsMobileMenuOpen(false);
    }

    if (isMarketDataMenuOpen) {
      setIsMarketDataMenuOpen(false);
    }
  };

  return (
    <HeaderContainer transparent={ transparent } hideSearch={ hideSearch }>
      <div className={ styles.headerContent }>
        <div className={ cn(styles.headerTopRow, { [styles.headerScroll]: isMobileMenuOpen }) }>
          <Link
            to="/"
            name="finsightLogoLink"
            className={ styles.logo }
            onClick={ closeMenu }
          >
            <i
              className={ styles.logoWings }
              dangerouslySetInnerHTML={ { __html: logoWings } }
            />
            <i
              className={ styles.logoCompanyName }
              dangerouslySetInnerHTML={ { __html: finsightLogoText } }
            />
          </Link>
          <Icon
            type={ isMobileMenuOpen ? IconType.close : IconType.hamburgerMenu }
            className={ styles.burgerButton }
            onClick={ toggleMobileMenu }
            data-test="mobileMenuBurgerButton"
          />
          <div className={ styles.toolbarContainer }>
            <MarketDataMenuButton
              buttonClassName={ styles.hideMarketDataBtnWithOpenedSearchMenu }
            />
            <SearchInput />
          </div>
          { (isScreenS() && isMobileMenuOpen) && <MarketDataMenu /> }
          { !(isSearchResultsOpen && isScreenS()) && (
            <div className={ cn(styles.linksButtonsContainer, styles.premiumizationLinksContainer) }>
              <div>
                <Link
                  onClick={ closeMenu }
                  className={ styles.solutionsButton }
                  to={ URL.PRICING }
                  name="pricing"
                >
                  <div className={ styles.iconContainer }>
                    <Icon type={ IconType.price } />
                    <span>Pricing</span>
                  </div>
                </Link>
                <Link
                  onClick={ closeMenu }
                  className={ styles.solutionsButton }
                  to={ URL.SOLUTIONS }
                  name="solutions"
                >
                  <div className={ styles.iconContainer }>
                    <Icon type={ IconType.layersGroup } />
                    <span>Solutions</span>
                  </div>
                </Link>
              </div>
              <UserProfile />
            </div>
          ) }
        </div>
        { !isScreenS() && isMarketDataMenuOpen && (
          <div className={ styles.innerMenuWrapper }>
            <MarketDataMenu />
          </div>
        ) }
        <SearchResultMenu />
      </div>
    </HeaderContainer>
  );
};

export default memo(Header);
