import { getMarketOverviewBreadcrumbsRoute, getParentBreadcrumbsRoute } from '@/finsight/ui/common/helpers';

/**
 * @param {Object} filters
 * @param {Object} parent
 * @param {Object} issuer
 * @return {Array}
 */
const getIssuerBreadcrumbs = (filters, parent, issuer) => ([
  getMarketOverviewBreadcrumbsRoute(filters, true),
  getParentBreadcrumbsRoute(parent),
  {
    title: issuer.name,
    isActive: true,
  },
]);

export default getIssuerBreadcrumbs;
