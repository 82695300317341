export const SEPARATOR = '&';
export const SECTORS_COLUMNS_CONFIG = [2, 3, 1, 2];
export const INDUSTRIES_COLUMNS_CONFIG = [3, 3, 2, 4];
export const SEARCH_RESULT_FIRST_COLUMN_CONFIG = [
  {
    key: 'industryAndSubindustries',
    title: 'Industries',
  },
  {
    key: 'sectorAndSubsectors',
    title: 'ABS Sectors',
  },
  {
    key: 'parents',
    title: 'Parents',
  },
  {
    key: 'issuers',
    title: 'Issuers',
  },
];
export const SEARCH_DEBOUNCE = 250;
export const MARKET_DATA_MENU_BUTTON_ID = 'marketDataButton';
export const HEADER_SEARCH_INPUT_ID = 'headerSearchInput';
