import React from 'react';
import UserPermission from '@/users/domain/UserPermission';
import CheckAccessPermissions from '@/users/application/Session/CheckAccessPermissions';
import { Spinner } from '@dealroadshow/uikit';
import UserProfileMenu from './UserProfileMenu';
import UserProfileFallback from './UserProfileFallback';

import styles from './userProfile.scss';

const UserProfileComponent = () => {
  const preloader = (
    <div className={ styles.logInBtnSpinnerWrapper }>
      <Spinner className={ styles.logInBtnSpinner } />
    </div>
  );

  return (
    <CheckAccessPermissions
      permissions={ UserPermission.managerAccess }
      fallback={ <UserProfileFallback /> }
      preloader={ preloader }
    >
      <UserProfileMenu />
    </CheckAccessPermissions>
  );
};

export default UserProfileComponent;
