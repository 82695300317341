import React, { FC } from 'react';
import styles from './styles.scss';

interface IProps {
  children: React.ReactNode,
}

const HomePageContentLayout: FC<IProps> = ({ children }) => (
  <div className={ styles.homePageContainer }>
    <div className={ styles.homePageContentHolder }>
      { children }
    </div>
  </div>
);

export default HomePageContentLayout;
