import * as actionTypes from './actionTypes';

export const initialState = {
  isFetching: false,
  items: {},
  initialFilters: {},
  appliedFilters: {},
  filtersPayload: null,
  dateFromMin: null,
  dateToMax: null,
};

export default function filters(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_RESEARCH_FILTER:
      return {
        ...state,
        isFetching: true,
      };
    case actionTypes.GET_RESEARCH_FILTER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        items: action.payload,
      };
    case actionTypes.GET_RESEARCH_FILTER_ERROR:
      return {
        ...state,
        isFetching: false,
      };
    case actionTypes.GET_RESEARCH_FILTER_BY_HASH:
      return {
        ...state,
        isFetching: true,
      };
    case actionTypes.GET_RESEARCH_FILTER_BY_HASH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        hashPayload: action.payload,
      };
    case actionTypes.GET_RESEARCH_FILTER_BY_HASH_ERROR:
      return {
        ...state,
        isFetching: false,
      };
    case actionTypes.RESEARCH_FILTER_PAYLOAD:
      return {
        ...state,
        filtersPayload: action.payload,
      };
    case actionTypes.RESEARCH_FILTER_APPLY:
      return {
        ...state,
        appliedFilters: action.payload,
      };
    case actionTypes.RESEARCH_INITIAL_FILTERS:
      return {
        ...state,
        initialFilters: action.payload,
      };
    case actionTypes.RESEARCH_FILTER_MIN_DATE_FROM:
      return {
        ...state,
        dateFromMin: action.payload,
      };
    case actionTypes.RESEARCH_FILTER_MAX_DATE_TO:
      return {
        ...state,
        dateToMax: action.payload,
      };
    case actionTypes.RESET_RESEARCH_FILTER_MIN_MAX_DATE:
      return {
        ...state,
        dateFromMin: null,
        dateToMax: null,
      };
    case actionTypes.RESET_RESEARCH_FILTER:
      return initialState;
    default:
      return state;
  }
}
