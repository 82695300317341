import { useSessionContext } from '@/users/application/Session/SessionContext';
import { useUserPlanContext } from '@/finsight/application/User/UserPlan/UserPlanContext';
import { USER_PLAN_ACCESS_LEVEL_MAP } from '@/finsight/application/User/UserPlan/helpers';
import { useEffect, useState } from 'react';

export const useCheckUserPlan = (requiredUserPlan) => {
  const { initialized, currentUser } = useSessionContext();
  const { plan } = useUserPlanContext();
  const [shouldRender, setShouldRender] = useState(
      USER_PLAN_ACCESS_LEVEL_MAP[plan] >= USER_PLAN_ACCESS_LEVEL_MAP[requiredUserPlan] && !!currentUser?.id,
  );

  useEffect(() => {
    initialized && setShouldRender(
        USER_PLAN_ACCESS_LEVEL_MAP[plan] >= USER_PLAN_ACCESS_LEVEL_MAP[requiredUserPlan] && !!currentUser?.id,
    );
  }, [plan, initialized, currentUser?.id]);

  return shouldRender;
};
