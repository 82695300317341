import React from 'react';
import cn from 'classnames';
import { useHeaderMenuContext } from '@/finsight/ui/common/components/header/Header/HeaderMenuContext';
import { useUserPlanContext } from '@/finsight/application/User/UserPlan/UserPlanContext';
import { UserPlanType } from '@/users/domain/UserPlan';
import CheckUserPlanAccess from '@/finsight/ui/common/components/CheckUserPlanAccess';

import styles from '@/finsight/ui/common/components/header/Header/header.scss';

const HeaderContainer = ({ children, transparent, hideSearch }) => {
  const {
    isMobileMenuOpen,
    isMarketDataMenuOpen,
    isSearchResultsOpen,
  } = useHeaderMenuContext();
  const { plan } = useUserPlanContext();

  return (
    <CheckUserPlanAccess
      requiredUserPlan={ UserPlanType.PRO }
      fallback={ (
        <div
          className={ cn(styles.headerContainer, {
            [styles.transparentBackground]: transparent,
            [styles.searchResultOpenedOnMobileView]: isSearchResultsOpen,
            [styles.menuOpenedOnMobileView]: isMobileMenuOpen || isMarketDataMenuOpen,
            [styles.hideSearchOnMobileView]: !isMobileMenuOpen && hideSearch,
          }) }
        >
          { children }
        </div>
      ) }
    >
      <div
        className={ cn(styles.headerContainer, {
        [styles.transparentBackground]: transparent,
        [styles.userProPlan]: plan === UserPlanType.PRO,
        [styles.searchResultOpenedOnMobileView]: isSearchResultsOpen,
        [styles.menuOpenedOnMobileView]: isMobileMenuOpen || isMarketDataMenuOpen,
        [styles.hideSearchOnMobileView]: !isMobileMenuOpen && hideSearch,
      }) }
      >
        { children }
      </div>
    </CheckUserPlanAccess>
  );
};

export default HeaderContainer;
