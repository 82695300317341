import React from 'react';
import cn from 'classnames';
import StickyFooter from '@/Framework/UI/Organisms/StickyFooter';
import { footerCollection } from './footerCollection';

import styles from './footer.scss';

const Footer = () => {
  return (
    <StickyFooter
      contentClassName={ cn(styles.content, styles.contentWidth, styles.footerGroupDivider) }
      navigationClassName={ cn(styles.navigation, styles.contentWidth) }
      linksContainerClassName={ styles.linksContainer }
      logoClassName={ styles.logoPosition }
      config={ footerCollection }
    />
  );
};

export default Footer;
