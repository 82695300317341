import { connect } from 'react-redux';
import MissingSomethingModalForm from './MissingSomethingModalForm';
import {
  resetFinsightModalForm,
  setIsMissingSomethingModalVisible,
  submitMissingSomethingModalForm,
} from '@/finsight/application/actions/modalForms/actions';
import * as selectors from '@/finsight/application/actions/modalForms/selectors';

const mapStateToProps = (state) => (
  {
    isVisible: selectors.isMissingSomethingModalVisible(state),
    isFetching: selectors.isFetching(state),
    isSubmitSucceed: selectors.isSubmitSucceed(state),
  }
);

const mapDispatchToProps = {
  resetModal: resetFinsightModalForm,
  submitForm: submitMissingSomethingModalForm,
  hideModal: () => setIsMissingSomethingModalVisible(false),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MissingSomethingModalForm);
