import cn from 'classnames';
import React, { PureComponent } from 'react';
import styles from '@/finsight/ui/components/static/Policies/Certificates/certificates.scss';

interface IProps {
  items: {
    id: string,
    src: string,
    alt: string,
    className?: string,
  }[],
}


const defaultProps = {
  items: []
};

class Certificates extends PureComponent<IProps, any> {
  constructor(props) {
    super(props);
    this.state = {
      loadedItemsIds: []
    };
  }

  /**
   * @param {String} id
   */
  onLoad = (id) => this.setState((prevState) => ({
    loadedItemsIds: [...prevState.loadedItemsIds, id]
  }));

  /**
   * @param {Object} certificate
   */
  renderCertificate = (certificate) => (
    <img
      key={ certificate.id }
      src={ certificate.src }
      alt={ certificate.alt }
      className={ cn(styles.certificate, { [certificate.className]: !!certificate.className }) }
      onLoad={ () => this.onLoad(certificate.id) }
    />
  );

  render() {
    return (
      <div className={ cn(styles.certificatesContainer,
        { [styles.isVisible]: this.state.loadedItemsIds.length === this.props.items.length }) }
      >
        { this.props.items.map(this.renderCertificate) }
      </div>
    );
  }
}

// @ts-ignore
Certificates.defaultProps = defaultProps;

export default Certificates;
