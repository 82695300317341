const config = {
  title: 'FINSIGHT',
  description: 'Explore global fixed income new issue pricing or streamline your capital market and corporate finance workflows with our award winning services.',
  openGraph: {
    url: 'https://finsight.com/',
    title: 'FINSIGHT',
    description: 'Explore global fixed income new issue pricing or streamline your capital market and corporate finance workflows with our award winning services.',
    type: 'website',
    site_name: 'FINSIGHT',
    images: [
      {
        url: 'https://assets.finsight.com/3c3dcbd33f390f00e159.png',
        width: 800,
        height: 600,
        alt: 'FINSIGHT',
        type: 'image/jpeg',
      },
    ],
  },
  twitter: {
    handle: '@handle',
    site: '@site',
    cardType: 'summary_large_image',
  },
};

export default config;
