export const GET_RESEARCH_FILTER = 'GET_RESEARCH_FILTER';
export const GET_RESEARCH_FILTER_SUCCESS = 'GET_RESEARCH_FILTER_SUCCESS';
export const GET_RESEARCH_FILTER_ERROR = 'GET_RESEARCH_FILTER_ERROR';

export const GET_RESEARCH_FILTER_BY_HASH = 'GET_RESEARCH_FILTER_BY_HASH';
export const GET_RESEARCH_FILTER_BY_HASH_SUCCESS = 'GET_RESEARCH_FILTER_BY_HASH_SUCCESS';
export const GET_RESEARCH_FILTER_BY_HASH_ERROR = 'GET_RESEARCH_FILTER_BY_HASH_ERROR';

export const RESEARCH_FILTER_PAYLOAD = 'RESEARCH_FILTER_PAYLOAD';
export const RESEARCH_FILTER_APPLY = 'RESEARCH_FILTER_APPLY';
export const RESEARCH_INITIAL_FILTERS = 'RESEARCH_INITIAL_FILTERS';
export const RESEARCH_FILTER_MIN_DATE_FROM = 'RESEARCH_FILTER_MIN_DATE_FROM';
export const RESEARCH_FILTER_MAX_DATE_TO = 'RESEARCH_FILTER_MAX_DATE_TO';
export const RESET_RESEARCH_FILTER_MIN_MAX_DATE = 'RESET_RESEARCH_FILTER_MIN_MAX_DATE';

export const RESET_RESEARCH_FILTER = 'RESET_RESEARCH_FILTER';
