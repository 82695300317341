import * as actionTypes from './actionTypes';

const initialState = {
  isFetching: false,
  isSubmitSucceed: false,
  isCorrectionModalVisible: false,
  isMissingSomethingModalVisible: false,
  error: {},
};

export default function modalForm(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SEND_CORRECTION_MODAL:
    case actionTypes.SEND_MISSING_SOMETHING_MODAL:
      return {
        ...state,
        isFetching: true,
      };
    case actionTypes.SEND_CORRECTION_MODAL_FAILURE:
    case actionTypes.SEND_MISSING_SOMETHING_MODAL_FAILURE:
      return {
        ...state,
        isFetching: false,
        isSubmitSucceed: false,
        error: action.payload,
      };
    case actionTypes.SEND_CORRECTION_MODAL_SUCCESS:
    case actionTypes.SEND_MISSING_SOMETHING_MODAL_SUCCESS:
      return {
        ...state,
        isSubmitSucceed: true,
      };
    case actionTypes.SET_IS_MISSING_SOMETHING_MODAL_VISIBLE:
      return {
        ...state,
        isMissingSomethingModalVisible: action.payload,
      };
    case actionTypes.SET_IS_CORRECTION_MODAL_VISIBLE:
      return {
        ...state,
        isCorrectionModalVisible: action.payload,
      };
    case actionTypes.RESET_FINSIGHT_MODAL_FORM:
      return initialState;
    default:
      return state;
  }
}
