import React from 'react';
import gdprCertificate from '@/finsight/ui/assets/certificates/gdprCert.png';
import mifidCertificate from '@/finsight/ui/assets/certificates/mifidCert.png';
import privacyShieldCertificate from '@/finsight/ui/assets/certificates/privacyShieldCert.png';
import soc2Certificate from '@/finsight/ui/assets/certificates/soc2Cert.png';
import URL from '@/finsight/Router/urlConstants';
import BackgroundMap from '@/finsight/ui/components/static/Company/BackgroundMap';
import Certificates from '@/finsight/ui/components/static/Policies/Certificates';
import styles from '@/finsight/ui/components/static/Policies/policies.scss';
import SubscriptionsBackground from '@/finsight/ui/components/static/Subscriptions/SubscriptionsBackground';

const certificates = (
  <Certificates
    items={ [
      {
        id: 'gdpr-id',
        src: gdprCertificate,
        alt: 'GDPR Certificate'
      },
      {
        id: 'mifid-id',
        src: mifidCertificate,
        alt: 'MiFID II Certificate'
      },
      {
        id: 'privacy-shield-id',
        src: privacyShieldCertificate,
        alt: 'Data Privacy Framework Certificate',
        className: styles.privacyShieldCertificate
      },
      {
        id: 'aicpa-id',
        src: soc2Certificate,
        alt: 'AICPA SOC 2 Certificate',
        className: styles.soc2Certificate
      }
    ] }
  />
);

export const staticPages = {
  [URL.POLICIES_TERMS]: {
    title: 'Terms of Use',
    tagLine: 'By utilizing our services, you agree to the below.'
  },
  [URL.POLICIES_PRIVACY]: {
    title: 'Privacy Policy',
    tagLine: 'It’s our job to protect our client’s data. Below is how we handle your personal information.'
  },
  [URL.POLICIES_SECURITY]: {
    title: 'Security Policy',
    tagLine: 'Information security is a top priority.',
    children: certificates
  },
  [URL.POLICIES_COMPLIANCE]: {
    title: 'Compliance Policy',
    tagLine: 'Finsight actively monitors global regulations to ensure compliance.',
    children: certificates
  },
  [URL.ABOUT]: {
    title: 'About Us',
    tagLine: 'Unparalleled visibility and actionable insights.'
  },
  [URL.CAREERS]: {
    title: 'Careers',
    tagLine: 'Wanted: Talented People!',
    headerBackgroundComponent: BackgroundMap
  },
  [URL.SOLUTIONS]: {
    title: 'Our Solutions',
    tagLine: 'Better tools for better execution.'
  },
  [URL.CONTACT]: {
    title: 'Contact Us',
    tagLine: 'Our Fanatical 24x7 Sales & Support teams are standing by.',
    headerBackgroundComponent: BackgroundMap
  },
  [URL.FEATURES]: {
    title: 'Features',
    tagLine: 'Instant fixed income new issue pricing.'
  },
  [URL.ISSUERS_LIST]: {
    title: 'List of Global Fixed Income Issuers',
    tagLine: 'A list of all fixed income issuers across all products and regions on Finsight.com'
  },
  [URL.SPONSORS_LIST]: {
    title: 'List of Global Fixed Income Parents',
    tagLine: 'A list of all fixed income parents across all products and regions on Finsight.com'
  },
  [URL.SUBSCRIPTIONS]: {
    title: 'Email Subscriptions',
    tagLine: 'Manage all of your Finsight email subscriptions in one place.',
    headerBackgroundComponent: SubscriptionsBackground
  },
  [URL.PRICING]: {
    title: 'Market Data Pricing',
    tagLine: 'Our Fanatical 24x7 Sales & Support are standing by.'
  },
  [URL.OPEN_MARKET]: {
    title: 'OpenMarket Profile',
    tagLine: 'All live roadshows across the globe for Investors.',
  },
};
