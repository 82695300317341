import debounce from 'lodash/debounce';
import React from 'react';
import { connect } from 'react-redux';
import {
  searchTextChanged,
  search,
} from '@/finsight/application/actions/header/actions';
import { SEARCH_DEBOUNCE } from '@/finsight/ui/common/components/header/Header/constants';
import SearchInput from './SearchInput';

function mapStateToProps(state) {
  return {
    value: state.headerData.searchText,
    pathname: state.router.location.pathname,
  };
}

function mapDispatchToProps(dispatch) {
  const searchDebounced = debounce(
    (productsList, regionsList) => dispatch(search(productsList, regionsList),
    ), SEARCH_DEBOUNCE);
  return {
    onChange: (e, productsList, regionsList) => {
      dispatch(searchTextChanged(e.target.value));
      if (e.target?.value?.trim()) {
        searchDebounced(productsList, regionsList);
      }
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(React.memo(SearchInput));
