import { SET_META_TAGS } from './actionTypes';

const initialState = {
  title: '',
  description: '',
  withSuffix: true,
};

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case SET_META_TAGS:
      return {
        ...state,
        title: payload.title,
        description: payload.description,
        withSuffix: payload.withSuffix,
      };
    default:
      return state;
  }
};
