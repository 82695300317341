import { Dependencies } from 'constitute';
import Request from '@/Framework/api/Rpc/Request';
import { RpcSuccess } from '@dealroadshow/json-rpc-dispatcher';
import FinsightWebRpcDispatcher from '@/finsight/application/DI/Rpc/finsight-web/HttpDispatcher';

@Dependencies(FinsightWebRpcDispatcher)
class BreadcrumbsRepository {
  constructor(protected rpc: typeof FinsightWebRpcDispatcher) {}

  getBreadcrumbs = async (viewId: number) => {
    const request = new Request('finsight_web.frontend.finsight.get_breadcrumbs', {
      issuerViewId: viewId,
    });
    const response = await this.rpc.call<RpcSuccess>(request);

    return response.getResult().payload;
  };
}

export default BreadcrumbsRepository;
