import { useCallback, useEffect, useRef, useState } from 'react';
import isServer from '@/Framework/Router/Next/isServer';
import throttle from 'lodash/throttle';

export enum Positions {
  Top = 'top',
  ScrollUp = 'scrollUp',
  ScrollDown = 'scrollDown',
  Bottom = 'bottom',
}

/**
 * Hack for diff of Safari and Chrome
 * @return {number} scrollHeight
 */
const getScrollHeight = () => {
  if (isServer()) return 0;
  return Math.max(
    document.body.scrollHeight, document.documentElement.scrollHeight,
    document.body.offsetHeight, document.documentElement.offsetHeight,
    document.body.clientHeight, document.documentElement.clientHeight,
  );
};

/**
 * Subtract the size of the visible part of the browser from the size of the document
 * to determine the bottom position of the scroll relative to the document.
 * @param {number} scrollHeight
 * @return {number} bottomScrollPosition
 */
const getBottomScrollPosition = (scrollHeight) => {
  if (isServer()) return 0;
  return scrollHeight.current - window.innerHeight;
};

// Recommended value 300ms if css animation speed is 200ms - 250ms
export const useScrollDirection = (threshold: number = 300) => {
  const [direction, setDirection] = useState<Positions>(Positions.Top);
  // We use useRef to avoid unnecessary re-creation of the function and update event handlers
  const prevScrollPosition = useRef<number>(0);
  const scrollHeight = useRef<number>(getScrollHeight());

  /**
   * Determines the direction of the scroll relative to the current and previous values of scrollYOffset
   */
  const determineDirection = useCallback(() => {
    // We get the bottom position of the scroll every time due to the dynamic height of the document
    const bottomScrollPosition = getBottomScrollPosition(scrollHeight);
    const currentScrollPosition = window.scrollY;

    switch (true) {
      case currentScrollPosition === 0:
        setDirection(Positions.Top);
        break;
      case bottomScrollPosition <= currentScrollPosition:
        setDirection(Positions.Bottom);
        break;
      case prevScrollPosition.current > currentScrollPosition:
        setDirection(Positions.ScrollUp);
        break;
      case prevScrollPosition.current < currentScrollPosition:
        setDirection(Positions.ScrollDown);
        break;
      default:
        setDirection(Positions.Top);
        break;
    }
    // Caching the current value for future function calls
    prevScrollPosition.current = currentScrollPosition;
  }, []);

  useEffect(() => {
    const updateScrollDirection = () => {
      const currentScrollPosition = window.scrollY;

      if (scrollHeight.current !== getScrollHeight()) {
        // If the height of the document has changed, we update the cached values
        scrollHeight.current = getScrollHeight();
        prevScrollPosition.current = currentScrollPosition;

        /**
         * Check if the top or bottom scroll position has been reached with the new document height.
         * If the top or bottom of the document is not reached, we skip the computation of the direction
         * and wait for the next call to the handler with a new cache of values.
         * To avoid false calculations associated with resizing the document.
         */
        const bottomScrollPosition = getBottomScrollPosition(scrollHeight);
        if (currentScrollPosition > 0 && currentScrollPosition < bottomScrollPosition) {
          return;
        }
      }
      determineDirection();
    };

    const onScroll = throttle(updateScrollDirection, threshold);

    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return direction === Positions.ScrollDown;
};

export default useScrollDirection;
