import * as actionTypes from './actionTypes';

const initialState = {
  isSubmitting: false,
  isFetching: false,
  subscriptionsData: [],
  subscriptionsFormInitialValues: {},
};

export default function subscriptions(state = initialState, action) {
  switch (action.type) {
    case actionTypes.INIT_SUBSCRIPTIONS_FORM:
      return {
        ...state,
        isFetching: true,
      };
    case actionTypes.SUBMIT_SUBSCRIPTIONS_FORM:
      return {
        ...state,
        isSubmitting: true,
      };
    case actionTypes.INIT_SUBSCRIPTIONS_FORM_SUCCESS:
      return {
        ...state,
        isFetching: false,
        subscriptionsData: action.payload.subscriptionsData,
        subscriptionsFormInitialValues: action.payload.subscriptionsFormValues,
      };
    case actionTypes.SUBMIT_SUBSCRIPTIONS_FORM_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
      };
    case actionTypes.INIT_SUBSCRIPTIONS_FORM_ERROR:
      return {
        ...state,
        isFetching: false,
      };
    case actionTypes.SUBMIT_SUBSCRIPTIONS_FORM_ERROR:
      return {
        ...state,
        isSubmitting: false,
      };
    case actionTypes.RESET_SUBSCRIPTIONS_STATE:
      return initialState;
    default:
      return state;
  }
}
