import React from 'react';
import cn from 'classnames';
import { useHeaderMenuContext } from '@/finsight/ui/common/components/header/Header/HeaderMenuContext';
import { Icon, IconType } from '@dealroadshow/uikit';

import { useDictionariesContext } from '@/finsight/application/Dictionaries/DictionariesContext';
import { useHeaderDataActiveMenuContext }
  from '@/finsight/ui/common/components/header/Header/HeaderMenuActiveDataContext';

import { EQUITIES } from './constants';
import SectionItem from './SectionItem';

import { IProduct } from '@/finsight/domain/vo/Product';
import { IRegion } from '@/finsight/domain/vo/Region';

import styles from './sidebar.scss';

const Sidebar = () => {
  const {
    productsList: products,
    regionsList: regions,
  } = useDictionariesContext();
  const {
    activeProduct,
    activeRegion,
    setActiveProduct,
    setActiveRegion,
  } = useHeaderDataActiveMenuContext();

  const { isActiveEquities, setIsActiveEquities } = useHeaderMenuContext();

  const onChange = (item?: IProduct | IRegion, type?: string) => {
    if (type === 'product') {
      setActiveProduct(item as IProduct);
      if (isActiveEquities) {
        setActiveRegion(regions[regions.length - 1] as IRegion);
        setIsActiveEquities(false);
      }
      return;
    }

    if (type === 'region') {
      setActiveRegion(item as IRegion);
      if (isActiveEquities) {
        setActiveProduct(products[0] as IProduct);
        setIsActiveEquities(false);
      }
      return;
    }

    setIsActiveEquities(!isActiveEquities);
  };

  const renderProductsItem = (product) => (
    <SectionItem
      key={ product.id }
      /* @ts-ignore */
      onClick={ () => onChange(product, 'product') }
      displayName={ product.displayName }
      mobileViewText={ product.mobileAbbreviation }
      /* @ts-ignore */
      isActive={ (product.id === activeProduct.id) && !isActiveEquities }
      abbreviation={ product.abbreviation }
    />
  );

  const renderRegionsItem = (region) => (
    <SectionItem
      key={ region.id }
      /* @ts-ignore */
      onClick={ () => onChange(region, 'region') }
      displayName={ region.name }
      mobileViewText={ region.abbreviation }
      /* @ts-ignore */
      isActive={ (region.id === activeRegion.id) && !isActiveEquities }
      abbreviation={ region.abbreviation }
    />
  );

  return (
    <div
      className={ styles.sidebarContainer }
      data-test="marketDataMenuSidebar"
    >
      <div className={ cn(styles.sectionTitle, styles.productsTitle) }>
        Credit Products
      </div>
      <div className={ styles.sectionItemGroup }>
        { products.length && products.map(renderProductsItem) }
      </div>
      <div className={ cn(styles.sectionTitle, styles.regionsTitle) }>
        Credit Regions
      </div>
      <div className={ styles.sectionItemGroup }>
        { regions.length && regions.map(renderRegionsItem) }
      </div>
      <div className={ cn(styles.sectionTitle, styles.regionsTitle) }>
        Equity Products
      </div>
      <div className={ styles.sectionItemSingle }>
        { /* @ts-ignore */ }
        <SectionItem
          key={ EQUITIES.id }
          onClick={ onChange }
          displayName={ EQUITIES.name }
          mobileViewText={ EQUITIES.mobileAbbreviation }
          isActive={ isActiveEquities }
          abbreviation={ EQUITIES.abbreviation }
        >
          <Icon
            type={ IconType.new }
            className={ styles.newBadge }
          />
        </SectionItem>
      </div>
    </div>
  );
};

export default Sidebar;
