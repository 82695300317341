import * as actionTypes from './actionTypes';
import * as constants from './constants';

const initialState = {
  collection: [],
  isCollectionFetching: false,
  isFetchingMoreDeals: false,
  bufferCollection: [],
  totalCount: 0,
  page: 1,
  perPage: constants.ITEMS_PER_PAGE,
  pageTitle: '',
  subscriptions: {
    isFetching: false,
    isSubscribed: false,
    subscriptionId: '',
  },
};

export default function deals(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_DEALS:
      return {
        ...state,
        isCollectionFetching: true,
      };
    case actionTypes.GET_DEALS_SUCCESS:
      return {
        ...state,
        page: state.page + 1,
        isCollectionFetching: false,
        totalCount: action.payload.totalCount,
        collection: action.payload.collection,
      };
    case actionTypes.GET_DEALS_FAILURE:
      return {
        ...state,
        isCollectionFetching: false,
      };
    case actionTypes.GET_DEALS_TILE:
      return {
        ...state,
        isCollectionFetching: true,
      };
    case actionTypes.GET_DEALS_TILE_SUCCESS:
      return {
        ...state,
        page: state.page + 1,
        isCollectionFetching: false,
        totalCount: action.payload.totalCount,
        collection: action.payload.collection,
        subscriptions: {
          ...state.subscriptions,
          isSubscribed: action.payload.subscriptionStatus?.isSubscribed,
          subscriptionId: action.payload.subscriptionStatus?.subscriptionId,
        },
      };
    case actionTypes.GET_DEALS_TILE_FAILURE:
      return {
        ...state,
        isCollectionFetching: false,
      };
    case actionTypes.GET_BUFFER_DEALS_SUCCESS:
      return {
        ...state,
        bufferCollection: action.payload.collection,
      };
    case actionTypes.MERGE_DEALS_WITH_BUFFER:
      return {
        ...state,
        collection: state.collection.concat(state.bufferCollection),
      };
    case actionTypes.GET_MORE_DEALS:
      return {
        ...state,
        isFetchingMoreDeals: true,
      };
    case actionTypes.GET_MORE_DEALS_SUCCESS:
      return {
        ...state,
        page: state.page + 1,
        bufferCollection: action.payload.collection,
        isFetchingMoreDeals: false,
      };
    case actionTypes.GET_MORE_DEALS_FAILURE:
      return {
        ...state,
        isFetchingMoreDeals: false,
      };
    case actionTypes.SET_PAGE_TITLE:
      return {
        ...state,
        pageTitle: action.payload,
      };
    case actionTypes.SET_DEALS_PAGE:
      return {
        ...state,
        page: action.payload,
      };
    case actionTypes.RESET_DEALS:
      return initialState;
    //  subscriptions deals
    case actionTypes.CREATE_DEALS_SUBSCRIPTION:
      return {
        ...state,
        subscriptions: {
          ...state.subscriptions,
          isFetching: true,
        },
      };
    case actionTypes.CREATE_DEALS_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        subscriptions: {
          ...state.subscriptions,
          isFetching: false,
          isSubscribed: true,
        },
      };
    case actionTypes.CREATE_DEALS_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        subscriptions: {
          ...state.subscriptions,
          isFetching: false,
        },
      };
    case actionTypes.RESET_DEALS_SUBSCRIBE_MODAL_FORM:
      return {
        ...state,
        subscriptions: {
          ...initialState.subscriptions,
        },
      };
    default:
      return state;
  }
}
