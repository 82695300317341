import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import finsightWebUrl from '@/finsight/infrastructure/finsightWebUrl';
import usersUrl from '@/users/infrastructure/usersUrl';
import config from '@/Framework/config';

const useGetLoginUrl = () => {
  const router = useRouter();
  const getLoginUrl = () => {
    const callbackUrl = finsightWebUrl.getPropagateCallbackUrl(router.asPath);
    return usersUrl.getLoginUrl(callbackUrl, config.tenant.finsight.code);
  };
  const [loginUrl, setLoginUrl] = useState(getLoginUrl());

  useEffect(() => {
    setLoginUrl(getLoginUrl());
  }, [router.pathname]);

  return loginUrl;
};

export default useGetLoginUrl;
