import React from 'react';
import Head from 'next/head';
import favicon16 from '@/finsight/ui/common/components/Favicon/assets/favicon-16.png';
import favicon32 from '@/finsight/ui/common/components/Favicon/assets/favicon-32.png';
import favicon64 from '@/finsight/ui/common/components/Favicon/assets/favicon-64.png';
import favicon128 from '@/finsight/ui/common/components/Favicon/assets/favicon-128.png';
import finsightIosIcon76 from '@/finsight/ui/common/components/Favicon/assets/finsight-ios-icon-76.png';
import finsightIosIcon120 from '@/finsight/ui/common/components/Favicon/assets/finsight-ios-icon-120.png';
import finsightIosIcon152 from '@/finsight/ui/common/components/Favicon/assets/finsight-ios-icon-152.png';
import finsightIosIcon167 from '@/finsight/ui/common/components/Favicon/assets/finsight-ios-icon-167.png';
import finsightIosIcon180 from '@/finsight/ui/common/components/Favicon/assets/finsight-ios-icon-180.png';

const Favicon = () => (
  <Head>
    <link rel="icon" href={ favicon16 } sizes="16x16" type="image/x-icon" />
    <link rel="icon" href={ favicon32 } sizes="32x32" type="image/x-icon" />
    <link rel="icon" href={ favicon64 } sizes="64x64" type="image/x-icon" />
    <link rel="icon" href={ favicon128 } sizes="128x128" type="image/x-icon" />
    <link rel="apple-touch-icon" href={ finsightIosIcon76 } sizes="76x76" />
    <link rel="apple-touch-icon" href={ finsightIosIcon120 } sizes="120x120" />
    <link rel="apple-touch-icon" href={ finsightIosIcon152 } sizes="152x152" />
    <link rel="apple-touch-icon" href={ finsightIosIcon167 } sizes="167x167" />
    <link rel="apple-touch-icon" href={ finsightIosIcon180 } sizes="180x180" />
  </Head>
);

export default Favicon;
