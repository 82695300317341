import cn from 'classnames';
import React, { useState, memo } from 'react';
import ProfileItem from './ProfileItem';
import { IProfileItem } from '@/finsight/ui/common/components/header/Header/MarketDataMenu/Content/types';
import {
  useHeaderDataActiveMenuContext,
} from '@/finsight/ui/common/components/header/Header/HeaderMenuActiveDataContext';

import styles from './profile.scss';

interface IProfileProps {
  // TODO: modify after redux ts coverage
  beta: boolean,
  name: string,
  id: string,
  link: string,
  children: IProfileItem[],
  color: string,
  onProfileClick: ({ tool_name, profile_group }: { tool_name: string, profile_group: string }) => void,
  activeItemId: string,
}

const Profile = (props: IProfileProps) => {
  const [isExpanded, toggleIsExpanded] = useState<boolean>(false);

  const {
    activeMapping: contextActiveMapping,
  } = useHeaderDataActiveMenuContext();

  const hasDeals = (parentId: string, childId: string = null) => {
    let activeMapping = contextActiveMapping[contextActiveMapping.filterType];

    if (parentId && !childId) {
      // eslint-disable-next-line no-prototype-builtins
      return activeMapping.hasOwnProperty(parentId);
    }

    if (parentId && childId) {
      // eslint-disable-next-line no-prototype-builtins
      return activeMapping.hasOwnProperty(parentId) && activeMapping[parentId].hasOwnProperty(childId);
    }
    return undefined;
  };

  return (
    <div className={ cn(styles.profileContainer, {
      [styles.childrenHidden]: !isExpanded,
      [styles.profileContainerExpanded]: isExpanded,
    }) }
    >
      <ProfileItem
        item={ {
          beta: props.beta,
          name: props.name,
          id: props.id,
          link: props.link,
          color: props.color,
        } }
        name={ props.name }
        hasDeals={ hasDeals(props.id) }
        className={ cn(styles.parentProfile, { [styles.isExpanded]: isExpanded }) }
        onProfileClick={ props.onProfileClick }
        activeItemId={ props.activeItemId }
        isChildProfile={ false }
        isExpanded={ isExpanded }
        toggleIsExpanded={ toggleIsExpanded }
      />
      { !!props.children.length && (
        <div className={ cn(styles.childrenProfilesRow, { [styles.isExpanded]: isExpanded }) }>
          { props.children.map((item: IProfileItem) => (
            <ProfileItem
              key={ item.id }
              item={ item }
              name={ props.name }
              hasDeals={ hasDeals(props.id, item.id) }
              className={ styles.childProfile }
              onProfileClick={ props.onProfileClick }
              activeItemId={ props.activeItemId }
              isChildProfile
              isExpanded={ isExpanded }
              toggleIsExpanded={ toggleIsExpanded }
            />
          )) }
        </div>
      ) }
    </div>
  );
};

export default memo(Profile);
