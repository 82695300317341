import createAction from '@/Framework/State/Redux/createAction';
import HeaderRepository from '@/finsight/infrastructure/repository/HeaderRepository';
import * as actionTypes from './actionTypes';
import {
  mapSearchResult,
} from './helpers';
import createAsyncRequest from '@/Framework/State/Redux/createAsyncRequest';
import { SessionService } from '@/users/application/Session/SessionService';

/**
 * @return {Function}
 */
export const resetSearchResult = () => createAction(actionTypes.RESET_SEARCH_RESULT);

/**
 * @param {string} text
 */
export const searchTextChanged = (text) => createAction(actionTypes.SEARCH_TEXT_CHANGE, text);

/**
 * @return {Function}
 */
export const search = (productsList, regionsList) => async (dispatch, getState) => {
  const state = getState();

  if (!state.headerData.searchText?.trim()) {
    return;
  }

  const headerRepository = state.container.get(HeaderRepository);

  dispatch(createAsyncRequest({
    payload: state.headerData.searchText,
    method: headerRepository.search,
    types: [
      actionTypes.GET_HEADER_SEARCH_RESULTS,
      actionTypes.GET_HEADER_SEARCH_RESULTS_SUCCESS,
      actionTypes.GET_HEADER_SEARCH_RESULTS_FAILURE,
    ],
    callbacks: {
      responseHandler: (response) => mapSearchResult(response, productsList, regionsList),
    },
  }));
};

/**
 * @return {Function}
 */
export const onSearchResultItemClick = () => (dispatch) => dispatch(resetSearchResult());

/**
 * @return {Function}
 */
export const logout = () => (dispatch, getState) => {
  const sessionService = getState().container.get(SessionService);
  sessionService.logout({ redirect: false });
};
