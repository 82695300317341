import React, { useEffect, useState } from 'react';
import noop from 'lodash/noop';
import PageNotFound from '@/finsight/ui/common/components/PageNotFoundWrp';
import { PageContentLayout } from '@/finsight/ui/common/components/ContentLayouts';

interface IProps {
  isNotFound?: boolean,
  children?: React.ReactNode,
  resetRedirect?: Function,
  pathname: string,
}

const PageNotFoundChecker = (
  {
    isNotFound = false,
    children = null,
    pathname,
    resetRedirect = noop,
  }: IProps,
) => {
  const [invalidPathname, setInvalidPathname] = useState(null);

  useEffect(
    () => {
      if (isNotFound && (pathname !== invalidPathname)) {
        if (!invalidPathname) {
          setInvalidPathname(pathname);
        } else {
          resetRedirect();
          setInvalidPathname(null);
        }
      }
    },
    [isNotFound, pathname, invalidPathname],
  );

  if (isNotFound) {
    return (
      <PageContentLayout>
        <PageNotFound />
      </PageContentLayout>
    );
  }

  return children;
};

export default PageNotFoundChecker;
