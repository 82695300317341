import React from 'react';
import { UserPlanType } from '@/users/domain/UserPlan';
import { useCheckUserPlan } from '@/finsight/ui/common/components/CheckUserPlanAccess/useCheckUserPlan';

interface IProps {
  requiredUserPlan: UserPlanType,
  fallback?: React.FunctionComponent | React.JSX.Element,
  children?: React.FunctionComponent | React.JSX.Element,
}

const CheckUserPlanAccess = ({
  requiredUserPlan,
  fallback = null,
  children,
}: IProps) => {
  const shouldRender = useCheckUserPlan(requiredUserPlan);

  return <>{ shouldRender ? children : fallback }</>;
};

export default CheckUserPlanAccess;
