import React from 'react';
import { NextSeo } from 'next-seo';
import config from './config';
import { getPageTitle } from '@/finsight/ui/common/helpers';

export const SEO = {
  title: config.title,
  description: config.description,
  openGraph: {
    type: config.openGraph.type,
    site_name: config.openGraph.site_name,
    url: config.openGraph.url,
    title: config.title,
    description: config.description,
    images: config.openGraph.images,
  },
  twitter: config.twitter,
};

interface IPageSeo {
  title?: string,
  description?: string,
  url?: string,
}

export const PageSeo = ({ title, description, url }: IPageSeo) => (
  <NextSeo
    title={ getPageTitle(title) }
    description={ description }
    openGraph={ { url, title, description } }
  />
);
