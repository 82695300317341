import * as actionTypes from './actionTypes';
import BackendRedirectRepository from '@/finsight/infrastructure/repository/BackendRedirectRepository';
import createAction from '@/Framework/State/Redux/createAction';

export const resetRedirect = () => createAction(actionTypes.RESET_REDIRECT);

export const backendRedirect = (url) => async (dispatch, getState) => {
  /**
   * @type {RegionRepository}
   */
  const redirectRepository = getState().container.get(BackendRedirectRepository);
  dispatch(createAction(actionTypes.GET_BACKEND_REDIRECT));
  try {
    let route = await redirectRepository.getNewRoute(url);

    dispatch(createAction(actionTypes.GET_BACKEND_REDIRECT_SUCCESS, route));
  } catch (e) {
    dispatch(createAction(actionTypes.GET_BACKEND_REDIRECT_FAILURE));
  }
};

/**
 * @param {Boolean} payload
 */
export const setPageNotFound = (payload) => createAction(actionTypes.SET_REDIRECT_NOT_FOUND, payload);
