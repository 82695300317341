import React from 'react';

import styles from './backgroundMap.scss';

const renderCity = (className: string, i: number) => (
  <div
    key={ i }
    className={ className }
  >
    <div className={ styles.wrapper }>
      <div className={ styles.cityIndicator } />
      <div className={ styles.radarBigCircle } />
      <div className={ styles.radarSmallCircle } />
    </div>
  </div>
);

const BackgroundMap = () => (
  <div className={ styles.mapContainer }>
    <div className={ styles.wrapper }>
      { [
        styles.newYork,
        styles.miami,
        styles.london,
        styles.kyiv,
        styles.hongKong,
        styles.singapore
      ].map(renderCity) }
      <span className={ styles.mapImage } />
    </div>
  </div>
);

export default BackgroundMap;
