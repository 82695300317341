import React, { useRef, useEffect, useCallback } from 'react';
import cn from 'classnames';
import headerStyles from '@/finsight/ui/common/components/header/Header/header.scss';
import { Spinner } from '@dealroadshow/uikit';
import Sidebar from './Sidebar';
import Content from './Content';
import Equities from './Equities';
import { MARKET_DATA_MENU_BUTTON_ID } from '../constants';
import { useHeaderMenuContext } from '@/finsight/ui/common/components/header/Header/HeaderMenuContext';
import { isEscapeKey } from '@/Framework/browser/checkPressedKey';
import useFixWidthMarketDataMenuOnIE11 from '@/finsight/ui/common/components/header/Header/helpers/useFixWidthMarketDataMenuOnIE11';
import { useDictionariesContext } from '@/finsight/application/Dictionaries/DictionariesContext';

import styles from './marketDataMenu.scss';

const MarketDataMenu = () => {
  const marketDataMenuRef = useRef(null);
  const {
    isMarketDataMenuOpen,
    isActiveEquities,
    setIsMarketDataMenuOpen,
  } = useHeaderMenuContext();
  const { isHeaderInitialized } = useDictionariesContext();

  useFixWidthMarketDataMenuOnIE11(marketDataMenuRef);

  const marketDataHandlerEsc = useCallback((event) => {
    if (isMarketDataMenuOpen && isEscapeKey(event)) {
      setIsMarketDataMenuOpen(false);
    }
  }, [isMarketDataMenuOpen]);

  const marketDataMenuHandlerOutsideClick = useCallback((ev) => {
    if (!!ev.target && marketDataMenuRef.current && !marketDataMenuRef.current.contains(ev.target)) {
      if (
        isMarketDataMenuOpen
        && (ev.target?.id !== MARKET_DATA_MENU_BUTTON_ID)
        && (ev.target.offsetParent?.id !== MARKET_DATA_MENU_BUTTON_ID)
      ) {
        setIsMarketDataMenuOpen(false);
      }
    }
  }, [marketDataMenuRef.current, isMarketDataMenuOpen]);

  useEffect(() => {
    if (isMarketDataMenuOpen) {
      document.addEventListener('click', marketDataMenuHandlerOutsideClick);
      document.addEventListener('keydown', marketDataHandlerEsc);
    }
    return () => {
      document.removeEventListener('click', marketDataMenuHandlerOutsideClick);
      document.removeEventListener('keydown', marketDataHandlerEsc);
    };
  }, [
    isMarketDataMenuOpen,
    marketDataMenuHandlerOutsideClick,
    marketDataHandlerEsc,
  ]);

  return (
    <div
      ref={ marketDataMenuRef }
      className={
        cn(styles.container, {
          [styles.isMarketDataMenuOpen]: isMarketDataMenuOpen,
        })
      }
      data-test="marketDataMenu"
    >
      <Spinner
        overlay
        className={ headerStyles.spinner }
        overlayClassName={ styles.spinnerOverlay }
        isVisible={ !isHeaderInitialized }
      />
      { isHeaderInitialized && (
        <>
          <Sidebar />
          { isActiveEquities ? <Equities /> : <Content isOpen={ isMarketDataMenuOpen } /> }
        </>
      ) }
    </div>
  );
};

export default MarketDataMenu;
