export const GET_TRACE_FILTER = 'GET_TRACE_FILTER';
export const GET_TRACE_FILTER_SUCCESS = 'GET_TRACE_FILTER_SUCCESS';
export const GET_TRACE_FILTER_ERROR = 'GET_TRACE_FILTER_ERROR';

export const GET_TRACE_FILTER_BY_HASH = 'GET_TRACE_FILTER_BY_HASH';
export const GET_TRACE_FILTER_BY_HASH_SUCCESS = 'GET_TRACE_FILTER_BY_HASH_SUCCESS';
export const GET_TRACE_FILTER_BY_HASH_ERROR = 'GET_TRACE_FILTER_BY_HASH_ERROR';

export const TRACE_FILTER_PAYLOAD = 'TRACE_FILTER_PAYLOAD';
export const TRACE_FILTER_APPLY = 'TRACE_FILTER_APPLY';
export const TRACE_INITIAL_FILTERS = 'TRACE_INITIAL_FILTERS';
export const TRACE_FILTER_MIN_DATE_FROM = 'TRACE_FILTER_MIN_DATE_FROM';
export const TRACE_FILTER_MAX_DATE_TO = 'TRACE_FILTER_MAX_DATE_TO';
export const RESET_TRACE_FILTER_MIN_MAX_DATE = 'RESET_TRACE_FILTER_MIN_MAX_DATE';

export const RESET_TRACE_FILTER = 'RESET_TRACE_FILTER';
