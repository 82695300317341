import * as actionTypes from './actionTypes';

/**
 * This is a default filter set (the largest set of filters case - for Market Overview/Sector/Industry page)
 * Page Components set own set of filters for child components to fetch data by them
 */
const initialState = {
  // get %something by% - means filter to get data for/by - dealstream(overview)/sector/industry/company/deal
  getDealsBy: null,
  getTopCompaniesBy: null,

  targetViewIds: [], // when getting data for specific parent/issuer/deal by their id(s)

  productIds: [], // selected products (single in header or multiple in filters block)
  regionIds: [], // selected regions (single in header or multiple in filters block)

  dateFrom: null,
  dateTo: null,

  sector: null,
  industry: null,
  company: null,
  deal: null,
  isOverview: false,
  isConsumerOverview: false,
  initialized: false,
  skipNextUpdate: false,
};

export default function pageFilters(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_PAGE_FILTERS:
      return {
        ...state,
        ...action.payload,
        initialized: true,
      };
    case actionTypes.RESET_PAGE_FILTERS:
      return initialState;
    default:
      return state;
  }
}
